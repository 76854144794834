import {
  ref,
  onMounted,
  reactive,
  getCurrentInstance
} from 'vue'
import {
  getSelectKota,
  getSelectHarga,
  submitNewRute,
  deleteDataRute,
  getSingleRute
} from '@/domain/usecase'
import useVuelidate from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'

export default {
  name: 'RouteForm',
  props: {
    data: Object
  },
  emits: ['closeSidebar', 'reloadData'],
  setup(props: any, {
    emit
  }: any) {
    const app = getCurrentInstance()
    const propertiesProps = reactive(props)
    const Id = ref(null)
    const kotaOption = ref([])
    const hargaOption = ref([])
    const kotaAsalId = ref(null)
    const kotaTujuanId = ref(null)
    const hargaCompanyId = ref(null)
    const hargaRetailId = ref(null)
    const submitted = ref(false)
    const dataForm = reactive({
      kotaAsalId: null,
      kotaTujuanId: null,
      hargaCompanyId: null,
      hargaRetailId: null,
      travelDuration: 0
    })
    const isActive = ref(false)
    const title = ref('Tambah Rute')
    const {
      $confirm,
      $toast,
      $strInd
    } = app!.appContext.config.globalProperties

    const closeSidebar = () => {
      emit('closeSidebar')
    }

    const rules = {
      kotaAsalId: {
        required: helpers.withMessage('Kota Asal harus diisi', required)
      },
      kotaTujuanId: {
        required: helpers.withMessage('Kota Tujuan harus diisi', required)
      },
      hargaCompanyId: {
        required: helpers.withMessage('Harga Company Pelanggan harus diisi', required)
      },
      hargaRetailId: {
        required: helpers.withMessage('Harga Retail harus diisi', required)
      },
      travelDuration: {
        required: helpers.withMessage('Durasi Perjalanan harus diisi', required)
      },
    }

    const v$ = useVuelidate(rules, dataForm)

    const getRouteDetail = async () => {
      const response = await getSingleRute(propertiesProps.data.Id)
      if (response.error) {
        $toast.add({
          severity: 'error',
          detail: response.message,
          group: 'bc',
          life: 3000
        })
        closeSidebar()
      } else {
        const res = response.result
        Id.value = res.Id
        dataForm.travelDuration = Number(res.TripDuration)
        dataForm.kotaAsalId = res.OrigCityId
        dataForm.kotaTujuanId = res.DestCityId
        dataForm.hargaRetailId = res.RetailPriceId
        dataForm.hargaCompanyId = res.CompanyPriceId
      }
    }

    const getOptionCity = async () => {
      const filterController = '$select=Id,IsActive,Provinsi,Nama,PulauId,Kode&$filter=IsActive eq true'
      const response = await getSelectKota(filterController)
      // console.log('response ', response)
      kotaOption.value = response.result.map((x: any) => ({
        value: x.Id,
        label: x.Nama
      }))
    }
    const getOptionHarga = async () => {
      const filterController = '?$select=Id,Kode,IsDeleted,IsActive&$filter=IsActive eq true and IsDeleted eq false'
      const response = await getSelectHarga(filterController)
      // console.log('response harga : ', response)
      hargaOption.value = response.result.map((x: any) => ({
        value: x.Id,
        label: x.Kode
      }))
    }
    const checkResponse = async (response: any) => {
      console.log('response after', response)
      if (response.error && response.code !== 200) {
        $toast.add({
          severity: 'error',
          detail: response.message,
          group: 'bc',
          life: 3000
        })
      } else {
        if (propertiesProps.data) {
          $toast.add({
            severity: 'success',
            // summary: response.result.title,
            detail: $strInd.toast.successEdit,
            group: 'bc',
            life: 1500
          })
        } else {
          $toast.add({
            severity: 'success',
            detail: $strInd.toast.successSubmit,
            group: 'bc',
            life: 1500
          })
        }
        emit('reloadData')
        closeSidebar()
      }
    }
    const deleteData = () => {
      $confirm.require({
        header: 'Rute',
        message: $strInd.confirm.msgDelete,
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          const response = await deleteDataRute(propertiesProps.data.Id)
          // console.log('response delete', response)
          if (response.error) {
            $toast.add({
              severity: 'error',
              detail: $strInd.toast.errorDelete,
              group: 'bc',
              life: 3000
            })
          } else {
            $toast.add({
              severity: 'success',
              detail: response.result.detail ?? response.result.Detail,
              group: 'bc',
              life: 3000
            })
            emit('reloadData')
            closeSidebar()
          }
        }
      })
    }
    console.log('properties props', propertiesProps.data)
    if (propertiesProps.data) {
      title.value = 'Edit Rute'
    }
    const submitProccess = async () => {
      const dataSend = {
        // Id: propertiesProps.data.Id,
        origCityId: dataForm.kotaAsalId,
        destCityId: dataForm.kotaTujuanId,
        retailPriceId: dataForm.hargaRetailId,
        companyPriceId: dataForm.hargaCompanyId,
        TripDuration: Number(dataForm.travelDuration)
      }

      const response = await submitNewRute(dataSend, Id.value)
      checkResponse(response)
    }

    const submitData = (isFormValid: boolean) => {
      // console.log(isFormValid)
      submitted.value = true
      if (!isFormValid) {
        return
      }
      submitProccess()
    }
    onMounted(() => {
      getRouteDetail()
      getOptionCity()
      getOptionHarga()
    })
    return {
      kotaOption,
      kotaAsalId,
      kotaTujuanId,
      title,
      isActive,
      closeSidebar,
      getOptionCity,
      getOptionHarga,
      hargaOption,
      hargaCompanyId,
      hargaRetailId,
      propertiesProps,
      app,
      checkResponse,
      submitData,
      deleteData,
      rules,
      v$,
      submitted,
      Id,
      dataForm
    }
  }
}
