import {
  ref,
  onMounted,
  getCurrentInstance
} from 'vue'
import {
  getListRuteX,
  deleteDataRute,
  getSelectHarga,
  getAllPriceX
} from '@/domain/usecase'
import {
  useStore
} from 'vuex'
import RouteForm from '@/views/master/transactions/route/RouteForm.vue'
import { useRoute } from 'vue-router'
import { VueEternalLoading, LoadAction } from '@ts-pro/vue-eternal-loading'

export default {
  name: 'Route',
  components: {
    RouteForm,
    VueEternalLoading
  },
  setup() {
    const app = getCurrentInstance()
    const store = useStore()
    const dataRoute = ref('')
    const totalRecords = ref(0)
    const sliderActive = ref(false)
    const inputSearch = ref(null)
    const sendInputSearch = ref('')
    const dataForm = ref(null)
    const op = ref()
    const filterOp = ref()
    const hargaOption = ref([]) as any
    const tempLengthOfList = ref(0)
    const isInitial = ref(false)/** variable yang digunakan untuk mereset state menggunakan plugin vue-eternal-loading */
    const hargaRetail = ref({
      value: null,
      label: 'Semua Harga'
    })
    const hargaCompany = ref({
      value: null,
      label: 'Semua Harga'
    })
    const actionPage = ref(null)
    const route = useRoute()

    const {
      $confirm,
      $toast,
      $strInd
    } = app!.appContext.config.globalProperties

    const filters = {
      pageNumber: 1,
      pageSize: 10,
      totalRecords: 0,
      search: '',
      companyPriceId: null,
      retailPriceId: null
    }

    const priceFilters = {
      pageNumber: 1,
      pageSize: 10,
      totalRecords: 0
    }

    const getRoute = async () => {
      const response = await getListRuteX(filters)
      console.log('resRoute', response)
      filters.totalRecords = response.result.Count
      dataRoute.value = response.result.Data
      store.dispatch('hideLoading')
    }

    // const getRoute = async () => {
    //   const response = await getListRute({
    //     search: sendInputSearch.value,
    //     top: filter.top,
    //     skip: filter.skip,
    //     hargaCompany: hargaCompany.value.value,
    //     hargaRetail: hargaRetail.value.value,
    //     expand: '&$expand=KotaAsal($select=Id,Nama,Kode),HargaCompany($select=Kode,Version),HargaRetail($select=Kode,Version),KotaTujuan($select=Nama,Kode)',
    //     select: '&$select=Id,KotaAsalId,KotaTujuanId,HargaRetailId,HargaCompanyId,CreateDate,Durasi'
    //   })
    //   totalRecords.value = response.count
    //   dataRoute.value = response.result
    //   store.dispatch('hideLoading')
    // }
    const showDropDown = (evt: any, data: any) => {
      // console.log('masuk click drop', data, evt)
      dataForm.value = data
      op.value.toggle(evt)
    }
    const showDropDownFilter = (evt: any, inAction: any) => {
      actionPage.value = inAction
      filterOp.value.toggle(evt)
    }
    const onPage = (event: any) => {
      filters.pageNumber = event.page + 1
      getRoute()
    }
    const searchData = (val: any) => {
      // console.log('search Data', val)
      sendInputSearch.value = val.toLowerCase()
      filters.search = val
      getRoute()
    }
    const editData = (val: any) => {
      // console.log('ini val edit', val)
      dataForm.value = val
      sliderActive.value = true
    }
    const redirectAdd = () => {
      dataForm.value = null
      sliderActive.value = true
    }
    const closeSidebar = () => {
      sliderActive.value = false
    }
    const deleteData = (Id: any) => {
      // console.log('id masukk', Id)
      $confirm.require({
        header: 'Tipe Agent',
        message: $strInd.confirm.msgDelete,
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          const response = await deleteDataRute(Id)
          if (response.error) {
            $toast.add({
              severity: 'error',
              detail: $strInd.toast.errorDelete,
              group: 'bc',
              life: 3000
            })
          } else {
            $toast.add({
              severity: 'success',
              detail: response.result.detail ?? response.result.Detail,
              group: 'bc',
              life: 3000
            })
            getRoute()
          }
        }
      })
    }

    const getOptionHarga = async () => {
      const response = await getAllPriceX(priceFilters)
      console.log('response harga : ', response)
      // response.result.Data.map((x: any) => ({
      //   value: x.Id,
      //   label: x.Code
      // }))
      // eslint-disable-next-line array-callback-return
      response.result.Data.map((x: any) => {
        const obj = {
          value: x.Id,
          label: x.Code
        }
        console.log('obj', obj)
        hargaOption.value.push(obj)
      })
      console.log('harga option', hargaOption.value)
      if (tempLengthOfList.value <= 0) {
        hargaOption.value.unshift(hargaRetail.value)
      }
      tempLengthOfList.value = response.result.Data.length
    }

    const filterHargaRetail = (val: any) => {
      // console.log('ini val', val)
      if (actionPage.value === 'filterRetail') {
        hargaRetail.value = {
          value: val.value,
          label: val.label
        }
        filters.retailPriceId = val.value
      } else if (actionPage.value === 'filterCompany') {
        hargaCompany.value = {
          value: val.value,
          label: val.label
        }
        filters.companyPriceId = val.value
      }
      getRoute()
    }
    const currentTolltip = ref()
    const asTooltip = ref()
    const showKodeTolltip = (e: any, v: any) => {
      currentTolltip.value = v
      asTooltip.value.toggle(e)
    }

    const loadData = async ({ loaded, noMore }: LoadAction) => {
      console.log('xxx', tempLengthOfList.value)
      if (tempLengthOfList.value >= 10) {
        priceFilters.pageNumber += 1
        getOptionHarga().then((x: any) => {
          if (tempLengthOfList.value < 10) {
            /** menggunakan fungsi unutuk mereset state data kosong type dri interface plugin vue-eternal-loading */
            noMore()
          } else {
            /** menggunakan fungsi unutuk load state type dri interface plugin vue-eternal-loading */
            loaded()
          }
        })
      } else {
        noMore()
      }
    }

    onMounted(() => {
      store.dispatch('showLoading')
      getRoute()
      getOptionHarga()
    })
    return {
      dataRoute,
      getRoute,
      store,
      totalRecords,
      onPage,
      sliderActive,
      inputSearch,
      sendInputSearch,
      searchData,
      redirectAdd,
      closeSidebar,
      deleteData,
      editData,
      showDropDown,
      op,
      dataForm,
      filterOp,
      showDropDownFilter,
      hargaOption,
      hargaRetail,
      filterHargaRetail,
      hargaCompany,
      actionPage,
      route,
      currentTolltip,
      asTooltip,
      showKodeTolltip,
      filters,
      loadData,
      isInitial
    }
  }
}
